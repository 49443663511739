<template>
  <div v-if="dataResponse!=null">
    <h5 class="media-heading">
      {{ $t('Account image book bank') }}
    </h5>
    <b-img
      v-if="dataResponse['accountImageBookBank']"
      :src="dataResponse['accountImageBookBank']"
      fluid
      thumbnail
      width="250px"
      :alt="dataResponse['_id']"
      center
    />
    <b-img
      v-else
      :src="require('@/assets/images/blank/no_image.png')"
      fluid
      thumbnail
      width="100px"
      :alt="dataResponse['_id']"
    />
    <h5 class="media-heading mt-5">
      {{ $t('Account image qrcode') }}
    </h5>
    <b-img
      v-if="dataResponse['accountImageQrCode']"
      :src="dataResponse['accountImageQrCode']"
      fluid
      thumbnail
      width="250px"
      :alt="dataResponse['_id']"
      center
    />
    <b-img
      v-else
      :src="require('@/assets/images/blank/no_image.png')"
      fluid
      thumbnail
      width="100px"
      :alt="dataResponse['_id']"
    />
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BImg,
  BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
  },
  directives: {
    Ripple,
  },
  props: {
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  methods: {
    paymentStatusColor(data) {
      if (data === 'create') {
        return 'primary'
      }
      if (data === 'pending') {
        return 'warning'
      }
      if (data === 'success') {
        return 'success'
      }
      if (data === 'cancel') {
        return 'danger'
      }
      if (data === 'delete') {
        return 'danger'
      }
      if (data === 'error') {
        return 'danger'
      }
      return 'dark'
    },
  },
}
</script>
