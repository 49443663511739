<template>
  <div v-if="dataResponse!=null">

    <b-media
      vertical-align="top"
      class="mb-2"
    >
      <template #aside>
        <b-img
          v-if="dataResponse['bankId']['imageBank']"
          :src="dataResponse['bankId']['imageBank']"
          fluid
          thumbnail
          width="100px"
          :alt="dataResponse['bankId']['_id']"
        />
        <b-img
          v-else
          :src="require('@/assets/images/blank/no_image.png')"
          fluid
          thumbnail
          width="100px"
          :alt="dataResponse['bankId']['_id']"
        />
      </template>
      <h5 class="media-heading">
        {{ $t('Account name') }} : {{ dataResponse['accountName'] }}
      </h5>
      <b-card-text class="mb-0">
        {{ $t('Account number') }} : {{ dataResponse['accountNumber'] }}
      </b-card-text>
      <b-card-text class="mb-0">
        {{ $t('Bank') }} : {{ showFromCurrentLanguage(dataResponse['bankId']['name']) }} ({{ dataResponse['bankId']['shortName'] }})
      </b-card-text>
    </b-media>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BImg,
  BMedia,

} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BImg,
    BMedia,

  },
  directives: {
    Ripple,
  },
  props: {
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>
